import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgFor } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';
import { BusinessesForUserService } from 'src/app/services/businesses-for-user.service';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss'],
  standalone: true,
  imports: [NgFor, MatLegacyButtonModule]
})
export class LoginPopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<LoginPopupComponent>,private businessesForUserService:BusinessesForUserService) { }
  businesses = toSignal(this.businessesForUserService.businesses$);

  ngOnInit(): void {

  }
  closePopup(i) {
    this.dialogRef.close(i);
  }
  selectBusiness(i) {
    this.closePopup(i)
  }
}
