import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, ReactiveFormsModule, AbstractControl } from "@angular/forms";
import { DataService } from "src/app/services/data.service";
import { RequestService } from "src/app/services/request.service";
import { LoginService } from "../../../services/login.service";
import { TextService } from "../../../services/text.service";
import { ErrorService } from "src/app/services/error.service";
import { Subscription } from "rxjs";
import { Router, RouterLink } from "@angular/router";
import { HttpService } from "src/app/services/http.service";

import { text } from "src/texts/texts";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { NgIf, NgClass, NgFor, CommonModule } from "@angular/common";
import { BusinessesForUserService } from "src/app/services/businesses-for-user.service";

declare var $;

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  standalone: true,
  imports: [CommonModule, NgIf, ReactiveFormsModule, MatLegacyButtonModule, NgClass, MatLegacyFormFieldModule, MatLegacyInputModule, NgFor, RouterLink]
})
export class LoginComponent implements OnInit {
  checkBoxTxt =
    "קראתי והסכמתי לכל התנאים המפורטים בתנאי השימוש ובמדיניות שמירת הפרטיות";
  txt1 = "בתנאי השימוש";
  txt2 = "במדיניות שמירת הפרטיות";
  loginForm: UntypedFormGroup;
  validation_messages: any;
  loginById = true;
  selectedButtonIndex = 0;
  private loginSubscriber: Subscription;
  errorMessage: string = "";
  constructor(
    public dataService: DataService,
    private fb: UntypedFormBuilder,
    public textService: TextService,
    private requestService: RequestService,
    public loginService: LoginService,
    private errorService: ErrorService,
    private router: Router,
    private httpService: HttpService,
    private el: ElementRef,
    private businessesForUserService:BusinessesForUserService

  ) {
    sessionStorage.setItem("check", "check");
  }
  @ViewChild('idInput') idInput: ElementRef;
  @ViewChild('tzInput') tzInput: ElementRef;

  ngOnInit(): void {
    this.textService.setTextSource();
    this.loginService.initLoginPage();
    this.loginForm = this.fb.group({
      id: [
        "", []
      ],
      tz: [
        "", []
      ],
      phone: [
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern(/^05\d([-]{0,1})\d{7}$/),
        ]),
      ],
      terms: new UntypedFormControl(false, Validators.pattern("true")),
    }
    );

    this.validation_messages = {
      id: [
        { type: "required", message: "שדה חובה" },
        {
          type: "pattern",
          message: "יש להזין מספר חברה פרטית תקינה בת 9 ספרות בלבד",
        },
      ],
      tz: [
        { type: "required", message: "שדה חובה" },
        {
          type: "pattern",
          message: "יש להזין מספר זהות פרטית תקינה בת 9 ספרות בלבד",
        },
      ],
      phone: [
        { type: "required", message: "שדה חובה" },
        { type: "pattern", message: "יש להזין מספר טלפון תקין בספרות בלבד" },
      ],
      terms: [{ type: "pattern", message: "יש להסכים לתקנון" }],
    };
    this.focusOnInputField();
    this.updateValidators();
  }

  onSubmitUserDetails(valid, value): void {
    if (valid) {
      let { id, tz, phone } = value;
      this.dataService.showDataLoader = true;
      if (this.selectedButtonIndex === 0) {
        this.loginById = false;
      }
      else {
        this.loginById = true;
        id = tz;
      }
      this.loginSubscriber = this.loginService.login(id, phone, this.loginById).subscribe(
        (resp) => {
          this.httpService.updateHeader("token", resp.verify_token);
          sessionStorage.setItem("sms_phone", phone);
          this.dataService.showDataLoader = false;
          if (resp.businessesForUser.length > 1) {
            this.businessesForUserService.loadBusinesses(resp.businessesForUser);
            sessionStorage.setItem("businesses", JSON.stringify(resp.businessesForUser));
          }
          this.router.navigate(["/sms"]);
        },
        (err) => {
          //remove  this.errorService.handleError(err); and show popup instead
          this.dataService.showDataLoader = false;
          sessionStorage.removeItem("sms_phone");
          switch (err.message) {
            case "not found contact":
            case "ambiguous contact":
              this.errorMessage = text.login_error_message;
              break;

            case undefined:
              // this.errorMessage = 'נראה שמשהו השתבש. אפשר לנסות שוב ואם עדיין לא עובד - ליצור איתנו קשר ב - 2986*';
              this.errorMessage =
                this.textService.textSource["LOGIN_SCREEN_ERROR"];
              if (this.dataService.name == "gur") {
                this.errorMessage = this.errorMessage.replace(
                  "{phone}",
                  this.textService.textSource["GUR_PHONE_NUMBER"]
                );
              } else {
                this.errorMessage = this.errorMessage.replace(
                  "{phone}",
                  this.textService.textSource["NEEMANIM_PHONE_NUMBER"]
                );
              }
              break;
            default:
              break;
          }
          this.errorService.handleError(err);
        }
      );
    }
  }

  public validateIsraelId(control: UntypedFormControl) {
    let invalid = false;
    let id = String(control.value).trim(); //זה מחזיר את המחרוזת שהושמטה ללא רווחים מובילים ונגררים.
    if (id.length != 9) {
      invalid = true;
    }
    if (!invalid) {
      id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
      const calculated =
        Array.from(id, Number).reduce((counter, digit, i) => {
          const step = digit * ((i % 2) + 1);
          return counter + (step > 9 ? step - 9 : step);
        }) %
        10 ===
        0;
      invalid = !calculated;
    }

    if (invalid) {
      return { pattern: true };
    } else {
      return null;
    }
  }

  focusOnEmptyInput(e): void {
    if (this.loginForm.get("id").invalid) {
      setTimeout(() => {
        $(".id").focus();
      });
    } else if (this.loginForm.get("phone").invalid) {
      setTimeout(() => {
        $(".phone").focus();
      });
    } else if (this.loginForm.get("terms").invalid) {
      setTimeout(() => {
        $(".checkmark").focus();
      });
    }
  }

  ngOnDestroy(): void {
    if (this.loginSubscriber) {
      this.loginSubscriber.unsubscribe();
    }
  }

  selectButton(index: number) {
    this.selectedButtonIndex = index;    
    this.updateValidators();
    this.focusOnInputField();
  }
  focusOnInputField(): void {
    // פוקוס על השדה המתאים בהתבסס על הבחירה
    setTimeout(() => {
      if (this.selectedButtonIndex === 0 && this.idInput) {
        this.idInput.nativeElement.focus();
      } else if (this.selectedButtonIndex === 1 && this.tzInput) {
        this.tzInput.nativeElement.focus();
      }
    }, 0);
  }
  updateValidators() {
    const idValidators = this.selectedButtonIndex === 0 ? [Validators.required,Validators.pattern("\\d{9}")] : [];
    const tzValidators = this.selectedButtonIndex === 1 ? [Validators.required, this.validateIsraelId.bind(this)] : [];
    this.loginForm.get('id').setValidators(idValidators);
    this.loginForm.get('tz').setValidators(tzValidators);
    this.loginForm.get('id').updateValueAndValidity();
    this.loginForm.get('tz').updateValueAndValidity();
  }
}
