import {
  Component,
  OnInit,
  Input,
  NgZone,
  EventEmitter,
  Output,
} from "@angular/core";
import * as moment from "moment";
import { DataService } from "src/app/services/data.service";
import { environment } from "src/environments/environment";
import { ReactiveFormsModule, FormsModule, ValidatorFn } from "@angular/forms";
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  FormArray,
} from "@angular/forms";
import { RegisterPageService } from "src/app/services/register-page.service";
import { EmployeeService } from "src/app/services/employee.service";
import { Router } from "@angular/router";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { AppDateAdapter, APP_DATE_FORMATS } from "../../../format-datepicker";
import { TextService } from "../../../services/text.service";
import { PopupTypes } from "src/app/models/popuptype";
import { GeneralPopupComponent } from "../../common/general-popup/general-popup.component";
import { MatLegacyRadioModule } from "@angular/material/legacy-radio";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { NgClass, NgIf, NgFor } from "@angular/common";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
declare var $;
@Component({
    selector: "app-employee-card-personal-details-component",
    templateUrl: "./employee-card-personal-details-component.component.html",
    styleUrls: ["./employee-card-personal-details-component.component.scss"],
    providers: [
        { provide: DateAdapter, useClass: AppDateAdapter },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
    standalone: true,
    imports: [NgClass, NgIf, ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacyInputModule, NgFor, MatDatepickerModule, MatLegacySelectModule, MatLegacyOptionModule, MatLegacyRadioModule, GeneralPopupComponent]
})
export class EmployeeCardPersonalDetailsComponentComponent implements OnInit {
  @Input() editable = false;
  @Input() isItPersonalSection = true;
  @Input() employeeData;
  @Input() clickOnTab = false;
  @Output() updateDetails = new EventEmitter();
  isActive = true;
  name = environment.name;
  err_message;
  error = PopupTypes.error;
  userPersonalDetailsForm: UntypedFormGroup;
  userWorklDetailsForm: UntypedFormGroup;
  validation_messages: any;
  genders = ["זכר", "נקבה", "אחר"];
  genderTranslate = {
    Female: "נקבה",
    Male: "זכר",
    female: "נקבה",
    male: "זכר",
  };
  genderTranslateForUpdate = {
    נקבה: "Female",
    זכר: "Male",
  };
  employee_benefits_incapacity_to_work = [];
  employer_benefits_incapacity_to_work = [];
  eligibility_date = [];
  today;

  familyStatuses = [];
  eligibility_date_study_fund;
  allowance_compensations_percentage;
  deposit_for_compensation;
  study_fund_employee_deposit;
  study_fund_employer_deposit;
  study_fund_deposit;
  updateSuccess = false;
  name_title;

  constructor(
    public textService: TextService,
    public zone: NgZone,
    public fb: UntypedFormBuilder,
    private router: Router,
    public dataService: DataService,
    private registerPageService: RegisterPageService,
    private employeeService: EmployeeService
  ) {
    this.textService.setTextSource();

    this.today = new Date();
    this.employeeService.employeeDataArrived.subscribe((res) => {
      this.employeeData = this.employeeService.employeeData;
      this.updatePersonalDetails();
    });
  }

  ngOnInit(): void {
    this.validation_messages = this.registerPageService.getValidationMessages();
    this.employeeData = this.employeeService.employeeData;
    this.userPersonalDetailsForm = this.fb.group({
      firstname: new UntypedFormControl("", [
        Validators.compose([
          Validators.minLength(2),
          Validators.maxLength(40),
          Validators.pattern(/^[a-z \u0590-\u05fe \']+$/i),
        ]),
      ]),
      lastname: new UntypedFormControl("", [
        Validators.compose([
          Validators.minLength(2),
          Validators.maxLength(40),
          Validators.pattern(/^[a-z \u0590-\u05fe \']+$/i),
        ]),
      ]),
      gender: new UntypedFormControl("", []),
      id: new UntypedFormControl("", []),
      birthday: new UntypedFormControl("", [
        Validators.compose([this.isMyDateFormat]),
      ]),
      familystatus: new UntypedFormControl("", [,]),
      phone: new UntypedFormControl("", [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.compose([Validators.pattern(/^[0-9]+$/i)]),
      ]),
      email: new UntypedFormControl("", [
        Validators.compose([
          Validators.pattern(
            /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i
          ),
        ]),
      ]),
      city: new UntypedFormControl("", [
        Validators.maxLength(40),
        Validators.minLength(2),
      ]),
      street: new UntypedFormControl("", [
        Validators.maxLength(40),
        Validators.minLength(2),
      ]),
      number: new UntypedFormControl("", [Validators.maxLength(8)]),
    });

    this.userPersonalDetailsForm.disable();

    this.userWorklDetailsForm = this.fb.group({
      role: new UntypedFormControl("", [
        Validators.compose([Validators.minLength(2), Validators.maxLength(40)]),
      ]),
      employeeNumber: new UntypedFormControl("", [
        Validators.maxLength(40),
        Validators.minLength(2),
        Validators.pattern(/^[0-9]+$/i),
      ]),
      startDate: new UntypedFormControl("", []),
      employer_compensation: new UntypedFormControl("", []),
      employee_compensation: new UntypedFormControl("", []),
      allowance_compensation: new UntypedFormControl("", []),
      health_collective: new UntypedFormControl(""),
      section_14: new UntypedFormControl(false, []),
      date_pension_arrangement: new UntypedFormControl("", []),
      employer_compensation_loss_work: new UntypedFormControl("", []),
      employee_compensation_loss_work: new UntypedFormControl("", []),
      is_up_to_the_ceiling_of_the_training_fund: new UntypedFormControl("", []),
      employer_retirement: new UntypedFormControl("", [
        Validators.compose([
          Validators.max(10),
          Validators.min(0),
          Validators.compose([
            Validators.pattern("^[0-9]\\d*(\\.\\d{0,10})?$"),
          ]),
        ]),
      ]),
      employee_retirement: new UntypedFormControl("", [
        Validators.compose([
          Validators.max(10),
          Validators.min(0),
          Validators.compose([
            Validators.pattern("^[0-9]\\d*(\\.\\d{0,10})?$"),
          ]),
        ]),
      ]),
      date_training_fund: new UntypedFormControl("", []),
      other_study_fund_deposit: new UntypedFormControl("", []),
    });
    this.userWorklDetailsForm.disable();

    this.dataService.showGhostElements = true;

    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.registerPageService.getFormOptions().subscribe(
      (resp) => {
        this.familyStatuses = resp["family_status"];
        this.eligibility_date_study_fund = resp["eligibility_date_study_fund"];
        this.allowance_compensations_percentage =
          resp["allowance_compensations_percentage"];
        this.eligibility_date = resp["eligibility_date"];
        this.deposit_for_compensation = resp["deposit_for_compensation"];
        this.study_fund_employee_deposit = resp["study_fund_employee_deposit"];
        this.study_fund_employer_deposit = resp["study_fund_employer_deposit"];
        this.employer_benefits_incapacity_to_work =
          resp["employer_benefits_incapacity_to_work"];
        this.employee_benefits_incapacity_to_work =
          resp["employee_benefits_incapacity_to_work"];
        this.study_fund_deposit = resp["study_fund_deposit"];
        this.getDetails();

        this.employeeService.showGhostElementsTabs = false;
        this.dataService.showDataLoader = false;
        this.dataService.showGhostElements = false;
      },
      (err) => {
        window.location.reload();
        this.dataService.showGhostElements = false;
        this.dataService.showDataLoader = false;
        this.dataService.handleErrors(err);
      }
    );
  }
  updatePersonalDetails() {
    this.userPersonalDetailsForm.patchValue({
      birthday: (this.employeeData["personal_details"]["birthdate"] !=null
        ? moment(this.employeeData["personal_details"]["birthdate"]).toDate()
        : ""),
      city: this.employeeData["personal_details"]["city"],
      street: this.employeeData["personal_details"]["street"],
      number: this.employeeData["personal_details"]["bulding_number"],
      phone: this.employeeData["personal_details"]["mobile_phone"],
    });
  }
  getDetails() {
    this.isActive = this.employeeData["is_active"];
    let family_status = "";
    if (this.employeeData["personal_details"]["family_status"]) {
      this.familyStatuses.forEach((element) => {
        if (
          element["value"] ==
          this.employeeData["personal_details"]["family_status"]
        ) {
          family_status = element;
        }
      });
    }

    this.userPersonalDetailsForm.patchValue({
      firstname: this.employeeData["personal_details"]["first_name"],
      lastname: this.employeeData["personal_details"]["last_name"],
      gender:
        this.genderTranslate[this.employeeData["personal_details"]["gender"]],
      id: this.employeeData["personal_details"]["id_number"],
      birthday: (this.employeeData["personal_details"]["birthdate"] !=null
        ? moment(this.employeeData["personal_details"]["birthdate"]).toDate()
        : ""),
      familystatus: family_status,
      phone: this.employeeData["personal_details"]["mobile_phone"],
      email: this.employeeData["personal_details"]["email"],
      city: this.employeeData["personal_details"]["city"],
      street: this.employeeData["personal_details"]["street"],
      number: this.employeeData["personal_details"]["bulding_number"],
    });

    let allowance_compensations_percentage = "";
    let eligibility_date = "";
    let employer_compensation = "";
    let employee_compensation = "";
    let date_training_fund = "";
    let is_up_to_the_ceiling_of_the_training_fund = "";
    if (
      this.employeeData["social_allowances"][
        "allowance_compensations_percentage"
      ]
    ) {
      this.deposit_for_compensation.forEach((element) => {
        if (
          element["value"] ==
          this.employeeData["social_allowances"][
            "allowance_compensations_percentage"
          ]
        ) {
          allowance_compensations_percentage = element;
        }
      });
    }

    if (this.employeeData["social_allowances"]["eligibility_date"]) {
      this.eligibility_date.forEach((element) => {
        if (
          element["value"] ==
          this.employeeData["social_allowances"]["eligibility_date"]
        ) {
          eligibility_date = element;
        }
      });
    }
    if (this.employeeData["fund_study"]["study_fund_employer_deposit"]) {
      this.study_fund_employer_deposit.forEach((element) => {
        if (
          element["value"] ==
          this.employeeData["fund_study"]["study_fund_employer_deposit"]
        ) {
          employer_compensation = element;
        }
      });
    }
    if (this.employeeData["fund_study"]["study_fund_employee_deposit"]) {
      this.study_fund_employee_deposit.forEach((element) => {
        if (
          element["value"] ==
          this.employeeData["fund_study"]["study_fund_employee_deposit"]
        ) {
          employee_compensation = element;
        }
      });
    }
    if (this.employeeData["fund_study"]["date_training_fund"]) {
      this.eligibility_date_study_fund.forEach((element) => {
        if (
          element["value"] ==
          this.employeeData["fund_study"]["date_training_fund"]
        ) {
          date_training_fund = element;
        }
      });
    }

    if (this.employeeData["fund_study"]["study_fund_deposit"]) {
      this.study_fund_deposit.forEach((element) => {
        if (
          element["value"] ==
          this.employeeData["fund_study"]["study_fund_deposit"]
        ) {
          is_up_to_the_ceiling_of_the_training_fund = element;
        }
      });
    }

    this.userWorklDetailsForm.patchValue({
      role: this.employeeData["work_details"]["role"],
      employeeNumber: this.employeeData["work_details"]["employer_number"],
      startDate:
        this.employeeData["work_details"]["start_work_date"] === null
          ? ""
          : moment(
              this.employeeData["work_details"]["start_work_date"]
            ).toDate(),
      employer_retirement:
        this.employeeData["social_allowances"]["employer_benefits"],
      employee_retirement:
        this.employeeData["social_allowances"]["employee_benefits"],
      allowance_compensation: allowance_compensations_percentage,
      health_collective: this.employeeData["other"]["health_collective"]
        ? "true"
        : "false",
      section_14: this.employeeData["social_allowances"]["paragraph_14"],
      date_pension_arrangement:
        this.employeeData["social_allowances"]["eligibility_date"] === null
          ? ""
          : moment(
              this.employeeData["social_allowances"]["eligibility_date"]
            ).format("DD/MM/YYYY"),
      is_up_to_the_ceiling_of_the_training_fund:
        this.employeeData["fund_study"]["study_fund_deposit"],
      employer_compensation:
        this.employeeData["fund_study"]["study_fund_employer_deposit"],
      employee_compensation:
        this.employeeData["fund_study"]["study_fund_employee_deposit"],
      date_training_fund: moment(
        this.employeeData["fund_study"]["eligibility_date_study_fund"]
      ).format("DD/MM/YYYY"),
    });

    if (this.employeeData["fund_study"]["other_study_fund_deposit"] != null) {
      this.userWorklDetailsForm.patchValue({
        other_study_fund_deposit:
          this.employeeData["fund_study"]["other_study_fund_deposit"],
      });
    }
  }

  public isMyDateFormat(control: UntypedFormControl) {
    let date = moment(control.value).format("DD/MM/YYYY");

    let invalid = false;
    const da = date.split("/");
    let today = new Date();
    if (
      da.length !== 3 ||
      da[0].length !== 2 ||
      da[1].length !== 2 ||
      da[2].length !== 4
    ) {
      invalid = true;
    } else if (moment(date).diff(moment(today).format("DD/MM/YYYY")) > 0) {
      invalid = true;
    }

    if (invalid) {
      return { pattern: true };
    } else {
      return null;
    }
  }
  updateData() {
    if (!this.editable) {
      this.editable = true;
      if (this.isItPersonalSection) {
        this.userPersonalDetailsForm.enable();
        setTimeout(() => {
          $("#firstname").focus();
        });
      } else {
        this.userWorklDetailsForm.enable();
        setTimeout(() => {
          $("#role").focus();
        });
      }
    } else {
      if (
        (this.userPersonalDetailsForm.valid && this.isItPersonalSection) ||
        (this.userWorklDetailsForm.valid && !this.isItPersonalSection)
      ) {
        let body = this.isItPersonalSection
          ? {
              personal_details: {
                first_name: this.userPersonalDetailsForm.value.firstname,
                last_name: this.userPersonalDetailsForm.value.lastname,
                id_number: this.employeeData["personal_details"]["id_number"],
                mobile_phone: this.userPersonalDetailsForm.value.phone,
                email: this.userPersonalDetailsForm.value.email,
                gender:
                  this.genderTranslateForUpdate[
                    this.userPersonalDetailsForm.value.gender
                  ],
                family_status:
                  this.userPersonalDetailsForm.value.familystatus["value"],
                street: this.userPersonalDetailsForm.value.street,
                city: this.userPersonalDetailsForm.value.city,
                bulding_number: this.userPersonalDetailsForm.value.number,
                date_of_birth: moment(
                  this.userPersonalDetailsForm.value.birthday,
                  "YYYY-MM-DD"
                )
                  .format("YYYY-MM-DD")
                  .toString(),
              },
            }
          : {
              work_details: {
                role: this.userWorklDetailsForm.value.role,
                employer_number: Number(
                  this.userWorklDetailsForm.value.employeeNumber
                ),
                start_work_date: moment(
                  this.userWorklDetailsForm.value.startDate,
                  "YYYY-MM-DD"
                )
                  .format("YYYY-MM-DD")
                  .toString(),
              },
              other: {
                health_collective:
                  this.userWorklDetailsForm.value.health_collective == "true"
                    ? true
                    : false,
              },
              social_allowances: {
                employer_benefits:
                  this.userWorklDetailsForm.value.employer_retirement,
                employee_benefits:
                  this.userWorklDetailsForm.value.employee_retirement,
                allowance_compensations_percentage:
                  this.userWorklDetailsForm.value.allowance_compensation[
                    "value"
                  ],
                paragraph_14: this.userWorklDetailsForm.value.section_14,
              },
            };
        if (this.dataService.isItMobileDisplay()) {
          this.dataService.showDataLoader = true;
        }
        this.dataService.showGhostElements = true;
        this.employeeService
          .updateEmployeeDetails(this.employeeData["employment_id"], body)
          .subscribe(
            (resp) => {
              this.dataService.showGhostElements = false;
              this.dataService.showDataLoader = false;
              this.editable = false;
              if (this.isItPersonalSection) {
                this.userPersonalDetailsForm.disable();
              } else {
                this.userWorklDetailsForm.disable();
              }
              this.updateSuccess = true;
              this.updateDetails.emit();
            },
            (err) => {
              this.dataService.showGhostElements = false;
              this.dataService.showDataLoader = false;
              this.dataService.showErrorPopup = true;
              this.dataService.error_message_popup =
                this.textService.textSource["ERROR_GENERAL"];
              if (this.dataService.name == "gur") {
                this.dataService.error_message_popup =
                  this.dataService.error_message_popup +
                  " " +
                  this.textService.textSource["GUR_PHONE_NUMBER"];
              } else {
                this.dataService.error_message_popup =
                  this.dataService.error_message_popup +
                  " " +
                  this.textService.textSource["NEEMANIM_PHONE_NUMBER"];
              }
              this.dataService.handleErrors(err);
            }
          );
      }
    }
  }
}
