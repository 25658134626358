import { Injectable, OnDestroy } from "@angular/core";
import { environment } from "../../environments/environment";
import { PopupTypes } from "../models/popuptype";
import { HttpHandler } from "./httpHandler";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
// import { ReCaptchaV3Service } from "ng-recaptcha";
declare var $;
declare var grecaptcha: any;
@Injectable({
  providedIn: "root",
})
export class DataService extends HttpHandler {
  name: string = environment.name;
  showDataLoader = false;
  showGhostElements = false;
  showGhostElementsForUserName = false;
  showLoggedOutPopup = false;
  showApprovedRequestPopup = false;
  showIsExistsProcedurePopup = false;
  showNewAppointmentPopup = false;
  showErrorPopup = false;
  showNewAttachment = false;
  showmonthlyOrHourly = false;
  error_message_popup = "";
  isTheSideMenuOpen = false;
  logOutSubscriber: any;
  request_number: any;
  company_logo_binary = "";
  showHomePie: boolean;

  dateChanged = new Subject();
  dataArrived = new Subject();

  constructor(
    public router: Router,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    // private readonly recaptchaV3Service: ReCaptchaV3Service
  ) {
    super(_http, httpService, errorService);
  }

  closePopup(popupType: PopupTypes) {
    switch (popupType) {
      case PopupTypes.loggedOut:
        this.showLoggedOutPopup = false;
        break;
      case PopupTypes.error:
        this.showErrorPopup = false;
      case PopupTypes.newAttachment:
        this.showNewAttachment = false;
      case PopupTypes.newAppointment:
        this.showNewAppointmentPopup = false;
        case PopupTypes.monthlyOrHourly:
          this.showmonthlyOrHourly = false;
      case PopupTypes.isExistsProcedure:
        this.showIsExistsProcedurePopup = false;
        setTimeout(() => {
          $(".button").focus();
        }, 100);
        break;
      case PopupTypes.approvedRequest:
        this.showApprovedRequestPopup = false;

        break;
      default:
        break;
    }
  }

  logOut() {
    sessionStorage.removeItem("token");
    if (sessionStorage.getItem("a-auth-token")) {
      sessionStorage.removeItem("a-auth-token");
    }
    if (sessionStorage.getItem("businesses")) {
      sessionStorage.removeItem("businesses");
    }
    if (sessionStorage.getItem("subSelectedBusinessId")) {
      sessionStorage.removeItem("subSelectedBusinessId");
    }
    this.logOutSubscriber = this.logOutCall().subscribe();
    window.location.href = environment.main_site;
    // this.router.navigate(['/login'])
  }

  logOutBeforeLoginAdmin() {
    sessionStorage.removeItem("token");
    if (sessionStorage.getItem("a-auth-token")) {
      sessionStorage.removeItem("a-auth-token");
    }
    this.logOutSubscriber = this.logOutCall().subscribe();
    sessionStorage.clear();
  }
  getAdminToken() {
    return sessionStorage.getItem("a-auth-token");
  }

  logOutCall() {
    const url = "/logout";
    let body = {};
    return this.post(url, body);
  }

  handleErrors(err: any) {
    this.showDataLoader = false;
    switch (err.code) {
      case 401: //Unauthorized
        if (
          err.message == "Invalid token" ||
          err.message == "Session Expired"
        ) {
          return (this.showLoggedOutPopup = true);
        }
        break;

      default:
        break;
    }
  }

  AreYouLoggedIn() {
    if (sessionStorage.getItem("check")) {
      return false;
    } else {
      return true;
    }
  }

  getResourceText(resource, data) {
    let name_of_data = resource.substring(
      resource.lastIndexOf("{") + 1,
      resource.lastIndexOf("}")
    );
    let index = name_of_data.split(".");
    let dataReplace = data[index[0]];
    if (index.length > 1) {
      for (let i = 1; i < index.length; i++) {
        dataReplace = dataReplace[index[i]];
      }
    }

    let finaltext = resource.replace("{" + name_of_data + "}", dataReplace);

    if (dataReplace === null || dataReplace === undefined) {
      finaltext = "";
    }
    return finaltext;
  }

  changeNumStartingLocation(text) {
    const textWithOutNum = text.replace(/[0-9]/g, "");
    const num = text.match(/\d+/)[0];

    const newText = `${num} ${textWithOutNum}`;
    return newText;
  }

  isItMobileDisplay() {
    return window.innerWidth < 600;
  }

  ngOnDestroy() {
    this.logOutSubscriber.unsubscribe();
  }

  // executeRecaptcha() {
  //   return this.recaptchaV3Service.execute('submit')
  // }
}
