import { Injectable, Injector } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { Router, NavigationEnd } from "@angular/router";
import { tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class LoginService extends HttpHandler {
  constructor(
    private injector: Injector,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public router: Router
  ) {
    super(_http, httpService, errorService);
    // this.httpService.headers.subscribe(evt => console.log('subscribe!!!!'));
  }

  getAdminToken() {
    return sessionStorage.getItem("a-auth-token");
  }

  public login(id: string, phone: string, loginById: boolean) {
    const url =  "/login";
    let body = {
      id_number: id,
      phone_number: phone,
      loginById: loginById
    };
    return this.post(url, body);
  }
  chooseBusiness(accountId) {
    const url = "/login/chooseBusiness";
    return this.post(url, { accountId });
  }
  public initLoginPage() {
    if (sessionStorage.getItem("token")) {
      sessionStorage.removeItem("token");
    }
    if (sessionStorage.getItem("sms_phone")) {
      sessionStorage.removeItem("sms_phone");
    }
  }

  async reload() {
    const currentUrl = this.router.url;
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    await this.router.navigate([`/${currentUrl}`]);
    console.log('RELOAD AFTER BID changed');
  }

  public loginBySalesForceID(sfId: string) {
    const url = "/debug/login/sfID";
    let body = {
      'salesForceId': sfId,
    }
    return this.post(url, body)

  }
}
