import { Component, OnInit, HostListener } from "@angular/core";
import { environment } from "src/environments/environment";
import { HomepageService } from "src/app/services/homepage.service";
import { DataService } from "../../../services/data.service";
import * as moment from "moment";
import { Router } from "@angular/router";
import { PlatformLocation, NgIf, AsyncPipe } from "@angular/common";
import { TextService } from "../../../services/text.service";
import { FeedbackService } from "src/app/services/feedback.service";
import { Observable } from "rxjs";
import { ModalService } from "src/app/services/modal.service";
import { UploadFileComponent } from "../../modals/upload-file/upload-file.component";
import { HomeComponentComponent } from "../../generic-components/home-component/home-component.component";
import { HeaderComponent } from "../../common/header/header.component";
import { AllowedClassifiedInformationService } from "src/app/services/allowed-classified-information.service";
import { GeneralPopupComponent } from "../../common/general-popup/general-popup.component";
import { PopupTypes } from "src/app/models/popuptype";
import { toSignal } from "@angular/core/rxjs-interop";
@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  standalone: true,
  imports: [HeaderComponent, HomeComponentComponent, NgIf, UploadFileComponent, GeneralPopupComponent]
})
export class HomeComponent implements OnInit {
  details: any;
  meetings: any;
  titleName = "";
  loginDetails = "";
  name = environment.name;
  selectOptions = [];
  buttonsName: Array<string>;
  displayFeedback$ = toSignal(this.feedbackService.getDisplayFeedbackObs());
  allowedClassifiedInformation$ = toSignal(this.allowedClassifiedInformationService.getAssociatePersonAllowedClassifiedObs());
  modals: {};
  newAppointment = PopupTypes.newAppointment;
  aprovedRequest = PopupTypes.approvedRequest;
  isExistsProcedure = PopupTypes.isExistsProcedure;

  constructor(
    location: PlatformLocation,
    public dataService: DataService,
    public textService: TextService,
    private homepageService: HomepageService,
    private router: Router,
    private feedbackService: FeedbackService,
    private modalService: ModalService,
    private allowedClassifiedInformationService: AllowedClassifiedInformationService

  ) {
    this.textService.setTextSource();
    location.onPopState(() => {
      if (this.router.url === "/") {
        this.dataService.showDataLoader = true;
        window.history.forward();
      }
    });
    sessionStorage.removeItem("check");
  }


  ngOnInit(): void {
    this.dataService.showGhostElementsForUserName = true;
    this.homepageService.getHomepageData().subscribe(
      (resp) => {
        this.dataService.showGhostElementsForUserName = false;
        this.details = resp;
        let login_details = this.details["person"]["login_details"];
        if (login_details) {
          this.details["person"]["login_details"]["login_date"] = moment(
            login_details["login_date"]
          ).format("DD.MM.YY");
          this.loginDetails = this.dataService.getResourceText(
            this.textService.textSource["HOME_SCREEN_SUBTITLE"],
            this.details
          );
        }
        sessionStorage.setItem(
          "binary",
          this.details["person"]["contact"]["logo_base_64"]
        );
        this.dataService.company_logo_binary =
          this.details["person"]["contact"]["logo_base_64"];
        this.titleName = this.dataService.getResourceText(
          this.textService.textSource["HOME_SCREEN_TITLE"],
          resp
        );
      },
      (err) => {
        this.dataService.handleErrors(err);
      }
    );

    this.buttonsName = [
      this.textService.textSource["HOME_SCREEN_SECTION_TOP_TITLE"],
      this.textService.textSource["HOME_SCREEN_SECTION_TOP_BUTTON_2"],
    ];

    this.initiateSelectOption();
    // this.initiateSelectOption2();

    this.modalService.getModals().subscribe((modals) => {
      this.modals = modals;
    });
  }


  initiateSelectOption() {
    let today = moment();
    let presentQuarter = {
      quarter: today.quarter(),
      year: today.year(),
    };
    this.selectOptions.push(presentQuarter);

    for (let i = 0; i < 3; i++) {
      today = today.subtract(3, "months");
      let quarter = today.quarter();
      let year = today.year();
      this.selectOptions.unshift({ quarter: quarter, year: year });
    }
  }


}
