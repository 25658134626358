import { Component, OnInit, ElementRef } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { OtpService } from "src/app/services/otp.service";
import { UntypedFormBuilder, Validators, FormControl, ReactiveFormsModule } from "@angular/forms";
import { Subscription } from "rxjs";
import { ErrorService } from "src/app/services/error.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpService } from "src/app/services/http.service";
import { text } from "src/texts/texts";
declare var $;
import { TextService } from "../../../services/text.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { LoginPopupComponent } from "../login/login-popup/login-popup.component";
import { BusinessesForUserService } from "src/app/services/businesses-for-user.service";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { toSignal } from '@angular/core/rxjs-interop'
import { filter, switchMap, tap } from "rxjs/operators";

@Component({
  selector: "app-login-sms",
  templateUrl: "./login-sms.component.html",
  styleUrls: ["./login-sms.component.scss"],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacyInputModule, NgFor, MatLegacyButtonModule, NgClass]
})
export class LoginSmsComponent implements OnInit {
  missingOTP;
  text: any = text;
  phone_text: string;
  otpForm: any;
  validation_messages: any;
  phone_number: string = "0541234414";
  private otpSubscriber: Subscription;
  private resendOTPSubscriber: Subscription;
  errorMessage: any;
  infoCode;
  businessesForUser = toSignal(this.businessesForUserService.businesses$);
  constructor(
    public dataService: DataService,
    private otpService: OtpService,
    private fb: UntypedFormBuilder,
    private errorService: ErrorService,
    private router: Router,
    private el: ElementRef,
    private httpService: HttpService,
    public textService: TextService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private readonly businessesForUserService: BusinessesForUserService
  ) {



    this.textService.setTextSource();

  }
  openDialog() {
    const dialogRef = this.dialog.open(LoginPopupComponent);
    return dialogRef.afterClosed()
  }
  ngOnInit(): void {
    this.otpForm = this.fb.group({
      otp_number: ["", Validators.required],
    });
    let phone_number = sessionStorage.getItem("sms_phone");

    if (phone_number) {
      this.phone_number = phone_number;
      this.phone_number =
        this.phone_number.substr(7) + "****" + this.phone_number.substr(0, 3);
      this.phone_text = this.textService.textSource["CODE_SCREEN_SUBTITLE"];
      this.phone_text = this.phone_text.replace("{phone}", this.phone_number);
      this.infoCode = "קוד אימות בעל 6 ספרות נשלח למכשיר הנייד שלך";
      if (this.dataService.name == "gur") {
        this.missingOTP =
          this.textService.textSource["CODE_SCREEN_FIELD_COMMENT"] +
          " " +
          this.textService.textSource["GUR_PHONE_NUMBER"];
      } else {
        this.missingOTP =
          this.textService.textSource["CODE_SCREEN_FIELD_COMMENT"] +
          " " +
          this.textService.textSource["NEEMANIM_PHONE_NUMBER"];
      }
    } else {
      this.router.navigate(["/"]);
    }
    this.validation_messages = {
      otp_number: [{ type: "required", message: "שדה חובה" }],
    };
  }

  onSubmitOtp(valid, value) {
    if (valid) {
      let code = value.otp_number;
      this.dataService.showDataLoader = true;
      this.otpSubscriber = this.otpService.validateOtp(code).subscribe(
        (resp) => {
          this.dataService.showDataLoader = false;
          sessionStorage.removeItem("sms_phone");
          if (this.businessesForUser().length > 1) {
            this.openDialog().pipe(
              filter(result => result !== undefined), // Proceed only if the result is not undefined
              switchMap(result => 
                this.businessesForUserService.changeBusiness(result, false).pipe(
                  tap(() => {
                    if (!sessionStorage.getItem("sms_phone")) {
                      this.httpService.updateHeader("token", resp.access_token);
                      this.router.navigate(["/"]);
                    }
                  })
                )
              )
            ).subscribe({
              error: (err) => {
                this.dataService.showDataLoader = false;
                this.errorMessage = "אירעה שגיאה, אנא נסו שוב";
              }
            });
            
          }
          else {
            this.httpService.updateHeader("token", resp.access_token);
            this.router.navigateByUrl("/");
          }
        },
        (err) => {
          this.dataService.showDataLoader = false;
          switch (err.message) {
            case "wrong verification code":
              this.errorMessage = text.otp_error_message;
              break;
            case undefined:
            default:
              switch (err.code) {
                case 429:
                  this.errorMessage = text.otp_too_many;
                  break;

                default:
                  this.errorMessage = "אירעה שגיאה, אנא נסו שוב";
                  break;
              }
              break;
          }
          this.errorService.handleError(err);
        }
      );
    }
  }

  resendOTP() {
    setTimeout(() => {
      $("#id").focus();
    }, 200);

    this.dataService.showDataLoader = true;
    this.resendOTPSubscriber = this.otpService.resendOTP().subscribe(
      (resp) => {
        this.dataService.showDataLoader = false;
      },
      (err) => {
        this.dataService.showDataLoader = false;
        this.errorService.handleError(err);
      }
    );
  }

  ngOnDestroy(): void {
    if (this.otpSubscriber) {
      this.otpSubscriber.unsubscribe();
    }
    if (this.resendOTPSubscriber) {
      this.resendOTPSubscriber.unsubscribe();
    }
  }
}
