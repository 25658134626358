import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LoginService } from "./login.service";
import { tap } from "rxjs/operators";
import { DataService } from "./data.service";
import { Router } from "@angular/router";
import { FeedbackService } from "./feedback.service";
import { AllowedClassifiedInformationService } from "./allowed-classified-information.service";
import { LoginBusiness } from "../models/login-popup-business.model";


@Injectable({
  providedIn: "root",
})
export class BusinessesForUserService {
  private subBusinesses = new BehaviorSubject<LoginBusiness[]>([]);
  businesses$ = this.subBusinesses.asObservable();
  private subSelectedBusinessId = new BehaviorSubject(0);
  selectedBusinessId$ = this.subSelectedBusinessId.asObservable();
  needToShowAllSubect = new BehaviorSubject<boolean>(true)
  needToShowAll$ = this.needToShowAllSubect.asObservable();

  constructor(private readonly loginService: LoginService, public dataService: DataService,private feedbackService:FeedbackService,
    private allowedClassifiedInformationService:AllowedClassifiedInformationService, private router: Router) {
    if (sessionStorage.getItem("businesses")) {
      this.subBusinesses.next(JSON.parse(sessionStorage.getItem("businesses")));
    }
    if (sessionStorage.getItem("subSelectedBusinessId")) {
      this.subSelectedBusinessId.next(JSON.parse(sessionStorage.getItem("subSelectedBusinessId")));
    }
  }
  changeBusiness(Id, reloadPage = false) {
    sessionStorage.setItem("subSelectedBusinessId", Id);
    this.subSelectedBusinessId.next(+Id);
    const currentURL = window.location.href;
    const expectedURL = `/employee-card/`;
    this.dataService.showDataLoader = true;
    return this.loginService.chooseBusiness(this.subBusinesses.value[this.subSelectedBusinessId.value].id)
      .pipe(tap(() => {
        this.dataService.showDataLoader = false;
        if (currentURL.indexOf(expectedURL) !== -1) {
          this.router.navigate(['/my-employees']).then(x => { if (reloadPage) { this.reload(); } });
        }
        else if (reloadPage) { this.reload(); }
      }));
  }
  async reload() {
    this.needToShowAllSubect.next(false);
    this.dataService.showDataLoader = true;
    await this.delay(100);
    await this.getDisplayPermission();
    this.needToShowAllSubect.next(true);
    this.dataService.showDataLoader = false;
  }
  delay(mili: number) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(null);
      }, (mili));

    });
  }
  loadBusinesses(businesses) {
    this.subBusinesses.next(businesses);
  }

  async getDisplayPermission() {
    await this.feedbackService.getDisplayFeedback();
    await this.allowedClassifiedInformationService.getAssociatePersonAllowedClassified();
  }
}
